import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function BlogPageTemplate({ pageContext: { page } }) {
  return (
    <Layout>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />
      <article>
      <header
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `flex-end`,
            alignContent: `flex-end`,
            borderBottom: `1px solid #ccc`,
            marginBottom: `1rem`,
            paddingBottom: `.2rem`,
          }}
        >
          <h1
            style={{
              marginBottom: 0,
            }}
          >
            {page.frontmatter.title}
          </h1>

        </header>
        <section dangerouslySetInnerHTML={{ __html: page.html }} />
        <hr
          style={{
            marginBottom: `1em`,
          }}
        />
      </article>

    </Layout>
  )
}
